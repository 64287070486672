<template>
  <div id="app-layout">           
            <div class="home__title">
              <h2 class="home__title__bg">NOS PRESTATIONS</h2>
            </div>         
            <div class="pagecontent links__presta">
              <router-link to="Missions" class="links__presta__block">
                <i class="far fa-handshake links__icon"></i>
                <div class="links__text">
                  Nos missions
                </div>
              </router-link>
              <router-link to="Services" class="links__presta__block">
                <i class="fas fa-calculator links__icon"></i>
                <div class="links__text">
                  Services en ligne
                </div>
              </router-link>
              <router-link to="Guides" class="links__presta__block">
                <i class="fas fa-book links__icon"></i>
                <div class="links__text">
                  Guides pratiques
                </div>
              </router-link>
              <router-link to="Liensutiles" class="links__presta__block">
                <i class="fas fa-link links__icon"></i>
                <div class="links__text">
                  Liens utiles
                </div>
              </router-link>
            </div>
  </div>
</template>

<script>
  export default {
      metaInfo: {
    // Children can override the title.
    title: 'Nos Prestations- Office Notarial de Me Montagner',
    link: [
    {rel: 'canonical', href: 'https://angelique-montagner.notaires.fr/Prestations'}
   ]
  },
    name: "prestations"
  }
</script>

<style lang="scss">
$tertiary-color: #e5ac5f;


@keyframes service {
    0% {
        transform: translateY(10px);
        opacity: 0;
  }
      
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@for $i from 1 through 9 {
    .links__presta__block:nth-child(#{$i}n) {
        animation-delay: #{$i * 0.25}s; }
 }

.links__presta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 15px 0;
    box-sizing: border-box;
    margin-bottom: 40px;
    margin-top: 20px;
    @media screen and (max-width: 610px){
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
         }
    &__block {
            text-align: center;
            margin: 0 10px;
            width: 25%;
            animation: service 1000ms;
            animation-fill-mode: backwards;
            @media screen and (max-width: 610px){
                margin: 20px 10px;
                width: fit-content;
                 }
        }
}

.pagecontent {
    width: 75%;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: rgba(250,250,250,0.95);
    border-radius: 3px;
    @media screen and (max-width: 979px){
        width: 90%;
    }
}

.content {
    width: 75%;
    margin: auto;
    padding-bottom: 10px;
    background-color: rgba(250,250,250,0.9);
    border-radius: 3px;
    @media screen and (max-width: 979px){
        width: 90%;
    }
}

.home {
    display: block;
    &__title {        
        font-family: 'Quicksand', sans-serif;
        margin: 0;
        text-align: center;
        box-sizing: border-box;
        padding-top: 150px;
        padding-bottom: 150px;
        color: black;
        text-shadow: 0 0 1px $tertiary-color;
        &__bg {
            background-color: rgba(229, 172, 95, 0.8);
            margin: auto;
            box-sizing: border-box;
            padding: 15px;
            border-radius: 4px;
            width: fit-content;
        }
    }
}


.contact {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 30px;
    @media screen and (max-width: 549px){
        flex-direction: column;
        padding: 0 20px;
        box-sizing: border-box;
         }
    &__list {
    list-style-type: none;
    @media screen and (max-width: 720px){
        padding: 0;
        width: fit-content;
        margin: auto;
         }
    }
    &__link {
        display: flex;
        margin-bottom: 15px;
        text-decoration: none;
        color: black;
        flex-direction: row;
        align-items: center;
        &__text {
            margin: 0;
            text-decoration: none;
            color: black;
            @media screen and (max-width: 399px){
                white-space: nowrap;
                font-size: smaller;
                 }
            &:hover {
                text-decoration: none;
                color: black;
            }
            &:visited {
                text-decoration: none; 
                color: black;
                 }
            }    
        }
    &__icon {
            margin-right: 10px;
        }
    &__findus {
        @media screen and (max-width: 399px){
            overflow: hidden;
             }
    }
}

.bienvenue {
    display: flex;
    align-items: center;
    @media screen and (max-width: 699px){
        flex-direction: column;
         }
    &__img {
        width: 50%;
        margin: 20px;
        border-radius: 5px;
        @media screen and (max-width: 699px){
            width: 95%;
            margin-top: 10px;
             }
    }
    &__text{
        text-align: center;
        margin: auto;
    }
    &__word {
        color: $tertiary-color;
    }
}

.links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    padding: 15px 0;
    box-sizing: border-box;
    margin-bottom: 40px;
    margin-top: 20px;
    @media screen and (max-width: 450px){
        flex-direction: column;
         }
    &__icon {
        font-size: 80px;
        color: $tertiary-color;
        margin-bottom: 20px;
    }
    &__block {
        text-align: center;
        margin: 0 10px;
        @media screen and (max-width: 450px){
            margin: 20px 10px;
             }
    }
    &__text {
    border: 0.5px solid;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0.5px 0.5px 0px 0px $tertiary-color;
    transition: all 250ms;
    &:hover {
        background-color: lighten($color: $tertiary-color, $amount: 15);
    }
    }
}
</style>